
import Vue from 'vue'
import Layout from '@/components/Layout.vue'
export default Vue.extend({
  components: {
    Layout,
  },
  computed: {
    isTest() {
      const list = ['Test', 'ZyEchat', 'AEchat1', 'BEchat2']
      let flag = list.includes(this.$route.name || '') || false
      return flag
    },
  },
})
