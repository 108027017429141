
import Menu from '@/components/Menu.vue'
import Header from '@/components/Header.vue'

// import Footer from "@/components/Footer";
export default {
  components: {
    Menu,
    Header
    // Footer
  },
   data(){
    return {
      isActiving:true // 刷新当前路由标识
    }
  },
    watch:{
      '$store.state.companyId':{
            handler(val){
                if(val){
                  this.isActiving = false
                  this.$nextTick(()=>{
                    this.isActiving = true
                  })
                }
            },
            immediate:false
        }
  }
}
